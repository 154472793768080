import React from "react"

import { useStaticQuery, graphql } from "gatsby"

export default props => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/contacto.md/" }) {
        html,
        frontmatter{
          phone{
            data
            icon
            title
          }
          email{
            data
            icon
            title
          }
          address{
            data
            icon
            title
          }
          open{
            data
            icon
            title
          }
        }
      }
    }
  `)
  const infoContacto = data.markdownRemark.frontmatter
  return (
    <>
      <section className="py-5 my-5" style={{
        backgroundColor: "#fcfcfc"
      }}>
        <div className="container-lg d-flex flex-wrap" style={{
          maxWidth: '650px'
        }}>
          {Object.keys(infoContacto).map(key => (
            <div className="card border rounded-sm shadow mb-2 mr-2 flex-grow-1">
              <div class="card-body media">
                <i className={`${infoContacto[key].icon} fa-2x mr-3 text-primary`}></i>
                <div className="media-body">
                  <h5 className="mt-0">{infoContacto[key].title}</h5>
                  <p> {infoContacto[key].data}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section
        id="map"
        style={{
          maxHeight: "600px",
          width: "100%"
        }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
      </section>
    </>
  )
}
